import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import BasicSpeedDial from "./BasicSpeedDial";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Cancel,
  FilterAltRounded,
  PlayArrow,
  TravelExploreTwoTone,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../hooks";
import { useEffect, useContext } from "react";
import { NationsContext } from "../context";
import { getFlagPath } from "../utilities";


export default function FilterBar({
  type,
  recordCount,
  filter,
  aggiornaFiltri,
  getMethod,
  resetFiltri,
  setOpenTabella,
  setViewStatoWeb,
  setViewAdvFilter,
  setPageResetVar,
  pageResetVar,
  listStatoWeb,
  setViewResa,
  listaResa,
  setCounterApi,
  setPage,
  filtriAttivi
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [dataFrom, setdataFrom] = React.useState(null);
  const [dataTo, setdataTo] = React.useState(null);

  const [MittProvincia, setMittProvincia] = React.useState("");
  const [MittLocalita, setMittLocalita] = React.useState("");
  const [MittRagSoc, setMittRagSoc] = React.useState("");
  const [MittNazione, setMittNazione] = React.useState(" ");

  const [DestProvincia, setDestProvincia] = React.useState("");
  const [DestLocalita, setDestLocalita] = React.useState("");
  const [DestRagSoc, setDestRagSoc] = React.useState("");
  const [DestNazione, setDestNazione] = React.useState(" ");

  const [CommRagSoc, setCommRagSoc] = React.useState("");
  
  const [Riferimento, setRiferimento] = React.useState(" ");
  const [Reparto, setReparto] = React.useState(" ");
  const [TermPar, setTermPar] = React.useState(" ");
  const [TermArr, setTermArr] = React.useState(" ");



  const [Stato, setStato] = React.useState(" ");

  const [searchall, setSearchall] = React.useState("");
  const [resa, setResa] = React.useState(" ");

  const {
    state: { nations },
    getNationsListsData
  } = useContext(NationsContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const limit = 100;
  const offset = 0;

  const ChangeValueHandler = (key, value, setFunc) => {
    if (key === "all") {
      resetFiltri();
      setdataFrom("");
      setdataTo("");
      setMittProvincia("");
      setMittLocalita("");
      setMittRagSoc("");
      setMittNazione("");
      setStato("");
      setSearchall("");
      setResa("")
      setOpen(!open);
    } else {
      setFunc(value);
      aggiornaFiltri(key, value);
      //setPageResetVar(true);
      /* setTimeout(() => , 0.1 * 1000); */
    }
  };
  const ResetPage = () =>{
    setCounterApi(0)
    setPage(0)
  }

  useEffect(() => {
    ResetPage(false);
    //setCounterApi(0)
    getNationsListsData()

  }, []);

  useEffect(() => {
    ResetPage(true);

    if (filter?.searchall === "") {
      handleAnnullaRicerca()
    }
  }, [filter]);

  const navigate = useNavigate();

  const PressKey = (e) => {
    if (e === 13) {
      handleAvviaRicerca();
      //getMethod("", limit, offset, navigate, [], recordCount, false, filter);
    }
  };

  const handleAvviaRicerca = () => {
    getMethod("", limit, offset, navigate, [], recordCount, false, filter);
    ResetPage(true);
  };

  const handleAnnullaRicerca = () => {
    ResetPage(true);
    getMethod("", limit, offset, navigate, [], recordCount, false, filter)


  };


  let dimension = useWindowDimensions();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          border: 2,
          borderColor: (theme) => theme.palette.primary.light,
          borderRadius: 20,
          background: (theme) => theme.palette.background.paper,
          color: "black",
        }}
        elevation={0}
      >
        <Toolbar variant="dense">
          {setViewAdvFilter ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => setOpen(!open)}
            >
              <Slide in={true}>
                <FilterAltRounded color={"primary"} />
              </Slide>
            </IconButton>
          ) : (
            <></>
          )}

          <TextField
            hiddenLabel
            autoComplete="off"
            size="small"
            fullWidth
            value={searchall || ""}
            onChange={(e) =>
              ChangeValueHandler("searchall", e.target.value, setSearchall)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <Player
                    // src="https://assets8.lottiefiles.com/packages/lf20_nIuZ4T.json"
                    src="cors_json/lf20_nIuZ4T.json"
                    autoplay
                    style={{ height: 45, width: 45 }}
                  /> */}
                  {/* 
                        14/08/2024 Greg: Sostituisco con iconbutton 
                        Segnalato da E.Colcera, il tasto "search" companiva andando su BasicSpeedDial,
                        e ingannava perchè non effettuata alcuna ricerca, sostituito con iconbutton nello startAdornment
                        sostituito con freccia vs destra nel BasicSpeedDial
                  
                  */}
                  <IconButton onClick={() =>{handleAvviaRicerca()} }>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {filter?.searchall !== "" ? <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => ChangeValueHandler("searchall", "", setSearchall)}>
                    <Cancel />
                  </IconButton> : <></>}
                </>
              )


            }}
            variant="standard"
            placeholder={"Cerca ..."}
            onKeyPress={(e) => PressKey(e.charCode)}
          />

          {dimension ? <></> : <BasicSpeedDial  />}

        </Toolbar>
      </AppBar>
      <Stack direction={"row"} spacing={2}>
        {dimension ? <BasicSpeedDial /> : <></>}
      </Stack>
      <Box>
        {setViewAdvFilter ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={1}>
              <Card elevation={0}>
                <CardHeader
                  title={"Data"}
                  subheader={""}
                  avatar={<TravelExploreTwoTone />}
                />
                <CardContent>
                  <Stack direction={"row"} spacing={2}>
                    <DatePicker
                      label="da"
                      value={dataFrom || null}
                      onChange={(newValue) => {
                        ChangeValueHandler(
                          "DataIniziale",
                          newValue,
                          setdataFrom
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          size="small"
                          {...params}
                        />
                      )}
                    />
                    <DatePicker
                      label="a"
                      value={dataTo || null}
                      onChange={(newValue) => {
                        ChangeValueHandler("DataFinale", newValue, setdataTo);
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </CardContent>
              </Card>
              {/* { 
               filtriAttivi?.destinatario?.visible ? */}
                  <Card elevation={0}>
                  <CardHeader
                    title={"Destinatario" }
                    subheader={""}
                    avatar={<TravelExploreTwoTone />}
                  />

                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          size="small"
                          label="Ragione sociale"
                          variant="outlined"
                          value={DestRagSoc || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "DestRagSoc",
                              e.target.value,
                              setDestRagSoc
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Località"
                          variant="outlined"
                          autoComplete="off"
                          value={DestLocalita || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "DestLocalita",
                              e.target.value,
                              setDestLocalita
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          label="Provincia"
                          variant="outlined"
                          value={DestProvincia || ""}
                          onChange={(e) => {

                            ChangeValueHandler(
                             "DestProvincia",
                              e.target.value,
                              setDestProvincia
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="select-from-nations" size='small'>Nazione</InputLabel>
                          <Select
                            fullWidth
                            size="small"
                            labelId="select-from-nations"
                            id="select-from-nations"
                            value={DestNazione || ""}
                            label="Nazione"
                            onChange={(e) => {
                            ChangeValueHandler(
                                "DestNazione",
                                e.target.value,
                                setDestNazione
                              );
                            }}
                          >
                            <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                            {nations?.map((params, key) => (
                              <MenuItem key={key} value={params.nazioneiso}>
                                <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={
                                      params.nazioneiso?.toLowerCase() !== ""
                                        ? `${getFlagPath(window.location.href, params.nazioneiso)}`
                                        : ""
                                    }
                                    srcSet={
                                      params.nazioneiso?.toLowerCase() !== ""
                                        ? `${getFlagPath(window.location.href, params.nazioneiso)} 2x`
                                        : ""
                                    }
                                    alt=""
                                  />
                                  {params.descrizione}
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>                    
                    </Grid>
                  </CardContent>
                </Card>
              {/* : <></>
              } */}

              { filtriAttivi?.mittente?.visible ?
                  <Card elevation={0}>
                  <CardHeader
                    title={"Mittente" }
                    subheader={""}
                    avatar={<TravelExploreTwoTone />}
                  />

                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          size="small"
                          label="Ragione sociale"
                          variant="outlined"
                          value={MittRagSoc || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "MittRagSoc",
                              e.target.value,
                              setMittRagSoc
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Località"
                          variant="outlined"
                          autoComplete="off"
                          value={MittLocalita || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "MittLocalita",
                              e.target.value,
                              setMittLocalita
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          label="Provincia"
                          variant="outlined"
                          value={MittProvincia || ""}
                          onChange={(e) => {

                            ChangeValueHandler(
                             "MittProvincia",
                              e.target.value,
                              setMittProvincia
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="select-from-nations" size='small'>Nazione</InputLabel>
                          <Select
                            fullWidth
                            size="small"
                            labelId="select-from-nations"
                            id="select-from-nations"
                            value={MittNazione || ""}
                            label="Nazione"
                            onChange={(e) => {
                            ChangeValueHandler(
                                "MittNazione",
                                e.target.value,
                                setMittNazione
                              );
                            }}
                          >
                            <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                            {nations?.map((params, key) => (
                              <MenuItem key={key} value={params.nazioneiso}>
                                <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={
                                      params.nazioneiso?.toLowerCase() !== ""
                                        ? `${getFlagPath(window.location.href, params.nazioneiso)}`
                                        : ""
                                    }
                                    srcSet={
                                      params.nazioneiso?.toLowerCase() !== ""
                                        ? `${getFlagPath(window.location.href, params.nazioneiso)} 2x`
                                        : ""
                                    }
                                    alt=""
                                  />
                                  {params.descrizione}
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>                    
                    </Grid>
                  </CardContent>
                </Card>
              : <></>
              }

              { 
               filtriAttivi?.committente?.visible ?
                  <Card elevation={0}>
                  <CardHeader
                    title={"Committente" }
                    subheader={""}
                    avatar={<TravelExploreTwoTone />}
                  />

                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          size="small"
                          label="Ragione sociale"
                          variant="outlined"
                          value={CommRagSoc || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "CommRagSoc",
                              e.target.value,
                              setCommRagSoc
                            );
                          }}
                        />
                      </Grid>                  
                    </Grid>
                  </CardContent>
                </Card>
              : <></>
              }


              <Card elevation={0}>
                <CardHeader
                  title={"Riferimento"}
                  subheader={""}
                  avatar={<TravelExploreTwoTone />}
                />
                <CardContent>
                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      size="small"
                      label="Riferimento"
                      variant="outlined"
                      onChange={(e) =>
                        ChangeValueHandler(
                          "Riferimento",
                          e.target.value,
                          setRiferimento
                        )
                      }
                    />
                  </Stack>
                </CardContent>
              </Card>


              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={3}>
                  {setViewStatoWeb ? (
                    <Card elevation={0}>
                      <CardHeader
                        title={"Stato"}
                        subheader={""}
                        avatar={<TravelExploreTwoTone />}
                      />
                      <CardContent>
                        <Stack direction={"row"} spacing={2}>
                          <FormControl size="small" sx={{ width: "100%" }}>
                            <InputLabel 
                              id="demo-simple-select-label"
                              size='small'
                            >
                              Stato
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={Stato || ""}
                              label="Stato"
                              onChange={(e) =>
                                ChangeValueHandler(
                                  "StatoWeb",
                                  e.target.value,
                                  setStato
                                )
                              }
                            >
                              <MenuItem value={""}>Seleziona stato ...</MenuItem>
                              {listStatoWeb.map((res, i) => (
                                <MenuItem value={res.value} key={i}>{res.text}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      </CardContent>
                    </Card>
                  ) : <></>}
                  </Grid>
                  {  filtriAttivi?.termpart?.visible ?
                    <Grid item xs={12} md={6} lg={3}>
                      <Card elevation={0}>
                        <CardHeader
                          title={"Terminal di partenza"}
                          subheader={""}
                          avatar={<TravelExploreTwoTone />}
                        />
                        <CardContent>
                          <Stack direction={"row"} spacing={3}>
                            <TextField
                              sx={{ width: "100%" }}
                              autoComplete="off"
                              size="small"
                              label="Terminal di partenza"
                              variant="outlined"
                              onChange={(e) =>
                                ChangeValueHandler(
                                  "TermPar",
                                  e.target.value,
                                  setTermPar
                                )
                              }
                            />
                          </Stack>
                        </CardContent>
                      </Card>                    
                    </Grid>
                  :<></>}
                  {  filtriAttivi?.termarr?.visible ?
                    <Grid item xs={12} md={6} lg={3}>
                      <Card elevation={0}>
                        <CardHeader
                          title={"Terminal di arrivo"}
                          subheader={""}
                          avatar={<TravelExploreTwoTone />}
                        />
                        <CardContent>
                          <Stack direction={"row"} spacing={3}>
                            <TextField
                              sx={{ width: "100%" }}
                              autoComplete="off"
                              size="small"
                              label="Terminal di arrivo"
                              variant="outlined"
                              onChange={(e) =>
                                ChangeValueHandler(
                                  "TermArr",
                                  e.target.value,
                                  setTermArr
                                )
                              }
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                      
                    </Grid>
                  :<></>}
                  {  filtriAttivi?.reparto?.visible ?
                    <Grid item xs={12} md={6} lg={3}>
                      <Card elevation={0}>
                        <CardHeader
                          title={"Reparto"}
                          subheader={""}
                          avatar={<TravelExploreTwoTone />}
                        />
                        <CardContent>
                          <Stack direction={"row"} spacing={3}>
                            <TextField
                              sx={{ width: "100%" }}
                              autoComplete="off"
                              size="small"
                              label="Reparto"
                              variant="outlined"
                              onChange={(e) =>
                                ChangeValueHandler(
                                  "Reparto",
                                  e.target.value,
                                  setReparto
                                )
                              }
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                      
                    </Grid>
                  :<></>}
               </Grid>


              {setViewResa ? (
                <Card elevation={0}>
                  <CardHeader
                    title={"Resa"}
                    subheader={""}
                    avatar={<TravelExploreTwoTone />}
                  />
                  <CardContent>
                    <Stack direction={"row"} spacing={2}>
                      <FormControl size="small" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Resa
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={resa || " "}
                          label="Resa"
                          onChange={(e) =>
                            ChangeValueHandler(
                              "resa",
                              e.target.value,
                              setResa
                            )
                          }
                        >
                          <MenuItem value={" "}>Seleziona resa ...</MenuItem>
                          {listaResa.map((res, i) => (
                            <MenuItem value={res.value} key={i}>{res.text}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </CardContent>
                </Card>
              ) : null}
              <Stack direction={"row"} spacing={2} mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  color={"primary"}
                  endIcon={<PlayArrow />}
                  onClick={() => handleAvviaRicerca()}
                >
                  Avvia ricerca
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color={"warning"}
                  endIcon={<Cancel />}
                  onClick={() => ChangeValueHandler("all")}
                >
                  Resetta filtri
                </Button>
              </Stack>
            </Box>
          </Collapse>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
